import React from 'react'
import Helmet from 'react-helmet'
import loadable from '@loadable/component'

const Form = loadable(() => import('../components/Form'))

const Forms = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Form testType="General Enquiry" />
    <Form testType="Coaching Enquiry" />
    <Form testType="Venue Enquiry" />
    <Form testType="Booking Enquiry - Early" />
    <Form testType="Booking Enquiry - Standard" />
    <Form testType="Retreat Enquiry - Early" />
    <Form testType="Retreat Enquiry - Standard" />
  </>
)

export default Forms
